<template>
    <div>
        <div class="columns" >
            <div class="column">
                Week : <select class="select input" v-model="week">
                    <option :value="row" v-for="row in max_weeks" :key="row">{{ row }}</option>
                </select>
            </div>
            
            <div class="column">
                Year : <select class="select input" v-model="year">
                    <option :value="row" v-for="row in years" :key="row">{{ row }}</option>
                </select>
            </div>
        </div>

        <div class="notification is-warning" v-if="loading == true">
            Loading client invoices ...
        </div>
        <div class="notification " v-if="loading == false && client_groups.length == 0">
            No client invoices can be found matching your search criteria
        </div>

        
        <div v-if="loading == false && client_groups.length > 0">
            
            <table class="table is-fullwidth  is-hoverable is-size-7 " >
                <thead>
                    <tr>
                        <th>Group ID</th>
                        <th>Client Group</th>
                        <th>Email</th>
                        <th>Hrs Worked</th>
                        <th>Timesheets / Sent</th>
                        <th>No Ref.</th>
                        <th>Total Charge</th>
                        <th width="81">&nbsp;</th>
                        <th width="81">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="row in client_groups">
                        <tr :key="'row-' + row.id" class="">
                            <td>{{ row.id }}</td>
                            <td>{{ row.name }} <span class="tag is-link" v-if="row.client_type_id==4">Trust</span></td>
                            <td>{{ row.billing_profile.email }}</td>
                            <td>{{ row.hours_worked}}</td>
                            <td>{{ row.num_timesheets }} / {{ row.num_timesheets_sent}}
                            
                            </td>
                            <td style="padding:2px">
                                <button class="button is-small tag is-danger has-text-weight-bold" typep="button" v-if="row.num_timesheets_no_ref > 0"> {{ row.num_timesheets_no_ref}}</button>
                            </td>
                            <td>{{ row.total_charge}}</td>
                            <td style="padding:0px">
                                <a :href="API_NAME + '/billing/client-invoices?client_group_id=' + row.id + '&week=' + week + '&year=' + year" target="_blank"><button class="button is-small is-info " :disabled="working==true">Download</button></a>
                            </td>
                            <td style="padding:0px">
                                <div v-if="row.send_invoices == 1 ">
                                    <div v-if="row.multi_invoices==1">
                                        <button v-if="row.client_invoices_sent == 0 || row.client_invoices_sent == 1" :disabled="working==true" class="button is-small is-danger is-fullwidth"  @click="sendClientInvoicesMulti(row)" >Send Multi</button>
                                    </div>
                                    <div v-else>
                                        <button v-if="row.client_invoices_sent == 1" :disabled="working==true" class="button is-small is-success is-fullwidth"  @click="sendClientInvoices(row)">Send Again</button>
                                        <button v-if="row.client_invoices_sent == 0" :disabled="working==true" class="button is-small is-warning is-fullwidth"  @click="sendClientInvoices(row)">Send By Email</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    
                
                </tbody>

            </table>
            
        </div>
        
        
        
    </div>
</template>

<script>
import BookingService from '@/services/BookingService.js';
//import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'PayrollReport',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   client_groups: [],
                   loading: true,
                   showModal: false,
                   showNotesModal: false,
                   alertText: '',
                   modalBooking : [],
                   working:false,
                   booking_notes: '',
                   year: 2023,
                   week: 1,
                   years : [2021, 2022, 2023, 2024, 2025, 2026],
                   accessToken: '',
                   showInvoicesFor: 0,
                   API_NAME: process.env.VUE_APP_API_NAME,
                   
            }
        },
        methods: {
            
            async getClientInvoices() {
                if(this.week > 0 && this.year > 0)
                {
                    this.loading = true;
                    
                    BookingService.getClientInvoices(this.accessToken, this.week, this.year)
                    .then(
                        (client_groups => {
                            this.$set(this, "client_groups", client_groups);
                            this.loading = false
                            this.working = false
                        
                        }).bind(this)
                    );
                }
                
                
            },
            
           
            async sendClientInvoices(client_group) {
                if(confirm("Are you sure you want to send the invoices by email ?"))
                {
                    var formdata = { 
                        week: this.week,
                        year: this.year
                    }; 

                    this.working = true

                    BookingService.sendClientInvoices(this.accessToken, formdata, client_group.id)
                    .then( () => {
                        this.working=false;
                        client_group.client_invoices_sent = 1
                        }
                    )
                    .catch((error) => { 
                        if (error.response.status == 400) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                    })
                }
                else {
                    return false
                }
            },

            async sendClientInvoicesMulti(client_group) {
                if(confirm("Are you sure you want to send the invoices by email as a batch ?"))
                {
                    var formdata = { 
                        week: this.week,
                        year: this.year
                    }; 

                    this.working = true

                    BookingService.sendClientInvoicesMulti(this.accessToken, formdata, client_group.id)
                    .then( () => {
                        this.working=false;
                        client_group.client_invoices_sent = 1
                        }
                    )
                    .catch((error) => { 
                        if (error.response.status == 400) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                    })
                }
                else {
                    return false
                }
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClientInvoices()
            });
        
        },
        computed: {
           
        },
        watch: {
            week: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getClientInvoices();
                }
                localStorage.payroll_report_week = this.week
            },
            year: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getClientInvoices();
                }
                localStorage.payroll_report_year = this.year
            }
        },
        mounted() {
            if (localStorage.payroll_report_week) {
                this.week = localStorage.payroll_report_week;
            }
            
            if (localStorage.payroll_report_year) {
                this.year = localStorage.payroll_report_year;
           }
            
        },
    }
</script>