<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading rates ...
        </div>
        <div class="notification is-danger" v-if="loading == false && filtered_rates.length == 0">
            No rates can be found matching your search criteria
        </div>

       <level>
            <div class="column">
                              <select class="input" required v-model="start_date" >
                                <option value="">Select Date</option>
                                <option :value="row" v-for="row in unique_dates" :key="row">{{ row }}</option>
                              </select>
                            </div> 
                            <div class="column">
                              <select class="input" required v-model="client_type_id" >
                                <option value="">Select Client Type</option>
                                <option :value="row.client_type_id" v-for="row in unique_client_types" :key="row.client_type_id">{{ row.client_type_name }}</option>
                              </select>
                            </div>              
                         
       </level>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && filtered_rates.length > 0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Client Type</th>
                    <th>Grade</th>
                    <th>LoS</th>
                    
                    <th>Day</th>
                    <th>Pay Rate</th>
                    <th>Charge Rate</th>
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_rates" :key="row.id">
                    <td>{{ row.start_date }} </td>
                    <td>{{ row.client_type_name }}</td>
                    <td>{{ row.grade_name }}</td>
                    <td>{{ row.length_of_service }}</td>
                    
                    <td>{{ row.description }} ({{ row.rate }}) </td>
                    <td>{{ row.pay_rate }}</td>
                    <td>{{ row.charge_rate }}</td>
                    <td></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
//import MemberService from '@/services/MemberService.js';
//import MemberMixin from '@/mixins/MemberMixin.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    
      name: 'Rates',
        data: function () {
            return {
                
                   rates: [],
                   loading: true,
                   accessToken: '',
                   order_by: 'sage_number,surname, forename ASC',
                   start_date: '',
                   client_type_id: ''
                   
            }
        },
        methods: {
            async getRates() {
                UtilityService.getSimpleApiData(this.accessToken, "rates").then( (response) => {
                    this.loading=false
                    this.rates = response 
                }
                    
                   
                );
                
            },
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getRates();
                
            });
        
        },
        computed : {
            filtered_rates: function () {
            
            let filtered_rates =  this.rates.filter(function(u) {
                if(  u.active == 1) 
                {
                return true
                }
                else
                {
                return false
                }
            })

            if(this.start_date != '' )
            {
                filtered_rates = filtered_rates.filter(
                    row => (row.start_date != null && row.start_date == this.start_date ) 
                    
                )
            }

            if(this.client_type_id > 0 )
            {
                filtered_rates = filtered_rates.filter(
                    row => (row.client_type_id != null && row.client_type_id == this.client_type_id ) 
                    
                )
            }

            return filtered_rates
        
            },
            unique_dates: function() {
                return [...new Set(this.rates.map(rate => rate.start_date))];
            },
            unique_client_types: function() {
                const seen = new Map();
                return this.rates.filter(rate => {
                    if (!seen.has(rate.client_type_id)) {
                    seen.set(rate.client_type_id, true);
                    return true;
                    }
                    return false;
                }).map(rate => ({
                    client_type_id: rate.client_type_id,
                    client_type_name: rate.client_type_name
                }));
            }
        }
    }
</script>