<template>
    <div>
        <div class="columns" >
            <div class="column">
                Week : <select class="select input" v-model="week" :disabled="loading==true">
                    <option :value="row" v-for="row in max_weeks" :key="row">{{ row }}</option>
                </select>
            </div>
            
            <div class="column">
                Year : <select class="select input" v-model="year" :disabled="loading==true">
                    <option :value="row" v-for="row in years" :key="row">{{ row }}</option>
                </select>
            </div>
            <div class="column">
                <button class="button is-pulled-right mt-5 " :class="{'is-success': show_notes_only == true}" @click="show_notes_only = !show_notes_only">Show Bookings With Notes Only</button>
            </div>
            <div class="column">
                <input class="input mt-5" v-model="search" placeholder="search bookings ..." />
            </div>

            
        </div>

        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification " v-if="loading == false && filtered_bookings.length == 0">
            No bookings can be found matching your search criteria
        </div>

        <div v-if="loading == false && filtered_bookings.length > 0"> 
            <p>This is, in essence, the same bookings that are on the payroll report for the selected week. They should match.</p>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Client</th>
                        <th>Ward</th>
                        <th>Reference</th>
                        <th>Grade</th>
                        <th>Member</th>
                        <th>Shift Date</th>
                        <th>Shift Time</th>
                        <th>Break</th>
                        <th>Processed</th>
                        <th  v-show="show_notes_only == true">Notes</th>
                        <th width="60" v-show="show_notes_only == false">&nbsp;</th>
                        <th width="60" v-show="show_notes_only == false">&nbsp;</th>
                        <th width="60" v-show="show_notes_only == false">&nbsp;</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in filtered_bookings" :key="row.id" :class="{'has-background-warning' : row.timesheet_notes != null && row.timesheet_notes != ''}">
                        <td>{{ row.id }}</td>
                        <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                        <td>
                            <span v-if="row.requires_mileage == 1">{{ row.actual_mileage }} miles</span>
                            <span v-else>{{ row.ward_name }}</span>
                            
                        </td>
                        <td style="padding:0px" v-if="row.reference == ''">
                            <div class="tag " v-if="row.reference == ''">No reference</div>
                        </td>
                        <td v-if="row.reference != ''">
                            {{ row.reference }}
                        </td>
                        <td>{{ row.grade_code }}
                            <span class="tag is-danger" v-if="checkBookingGrade(row.client_type_id, row.grade_id) == true">Check Grade</span>
                        </td>
                        <td>{{ row.forename }} {{ row.surname  }}</td>
                        <td>{{ row.start_date }}</td>
                        <td>{{ row.actual_start_time }} - {{ row.actual_end_time }}</td>
                        <td>{{ row.actual_break_time}}</td>
                        <td>{{ row.timesheet_processed_date }}</td>
                        <td v-show="show_notes_only == true">{{ row.timesheet_notes}}</td>
                        <td v-show="show_notes_only == false"><button class="button is-small is-info" @click="showNotes(row.timesheet_notes)" v-if="row.timesheet_notes != null && row.timesheet_notes != ''">Notes</button></td>
                        <td v-show="show_notes_only == false"><button class="button is-link is-small" @click="viewTimesheetModal(row)" >Edit</button></td>
                        <td v-show="show_notes_only == false"><button class="button is-danger is-small " :disabled="working==true" @click="viewProcessModal(row)" :class="{'is-danger' : row.reference == ''}">Re-Process</button></td>
                        
                    </tr>
                
                </tbody>

            </table>

            <p>Total Bookings : {{ this.filtered_bookings.length }}</p>
        </div>

        <TimesheetModal :booking_id="selected_booking_id" :showModal="showTimesheetModal" @closeModal="closeTimesheetModal" @closeModalAndReload="getProcessedBookings" @reloadBookings="getProcessedBookings"/>
        

        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Process Timesheet {{ this.modalBooking.id }}</p>
                    <button aria-label="close" class="delete" @click="closeProcessModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form @submit.prevent="reprocessSingleTimesheet" v-if="this.processed==false">
                        <div class="control">
                                <button class="button is-success" type="submit" :disabled="working==true" >Re-Process</button>
                            </div>
                    
                        
                    </form>

                    <div v-if="this.processed==true">
                        <h4 class="title is-size-4">Generated Invoices</h4>

                        <table class="table is-striped is-size-7 is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Duration</th>
                                    <th>Rate</th>
                                    <th>Pay Rate</th>
                                    <th>Charge</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in invoices" :key="row.id">
                                    <td>{{ row.day }}</td>
                                    <td>{{ parseFloat(row.duration_mins / 60).toFixed(2) }}</td>
                                    <td>{{ row.rate_id }}</td>
                                    <td>{{ row.pay_rate }}</td>
                                    <td>{{ row.charge_rate }}</td>

                                </tr>
                            </tbody>
                        </table>

                        <div class="control">
                            <button class="button" @click="closeProcessModal()">Close</button>
                        </div>
                    </div>
                </section>
                
            </div>
        </div>

        <div class="modal" :class="{'is-active': showNotesModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card" >
                <header class="modal-card-head">
                    <p class="modal-card-title">Timesheet Notes</p>
                    <button class="delete" aria-label="close" @click="showNotesModal=false"></button>
                </header>
                <section class="modal-card-body " >
                    {{  this.booking_notes }}
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showNotesModal=false" :disabled="working==true">Close</button>
                </footer>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import BookingService from '@/services/BookingService.js';
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';
import BookingMixin from '@/mixins/BookingMixin.js';

import TimesheetModal from '@/components/Billing/TimesheetModal.vue';

export default {
    
      name: 'SavedTimesheets',
        mixins: [MemberMixin, BookingMixin],
        components: {
            TimesheetModal
        },
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   showModal: false,
                   showNotesModal: false,
                   showTimesheetModal: false,
                   alertText: '',
                   modalBooking : [],
                   working:false,
                   booking_notes: '',
                   year: '',
                   week: '',
                   years : [2021, 2022, 2023, 2024, 2025],
                   invoices: [],
                   processed : false,
                   accessToken: '',
                   selected_booking_id: '',
                   show_notes_only: false
                   
            }
        },
        methods: {
            closeProcessModal() {
                this.showModal=false
                this.processed = false
            },
            showNotes(timesheet_notes) {
                this.booking_notes = timesheet_notes

                this.showNotesModal = true
            },
            viewTimesheetModal(booking) {
                this.selected_booking_id = booking.id // Set this for the modal
                this.showTimesheetModal = true
            },
            async getProcessedBookings() {
                if(this.accessToken != "" && this.week > 0 && this.year > 0)
                {
                    this.loading=true
                    
                    UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_processed=1&week=" + this.week + "&year=" + this.year)
                    .then(
                        (bookings => {
                            this.$set(this, "bookings", bookings);
                            this.loading=false
                            this.showTimesheetModal = false
                        }).bind(this)
                    );
                }
            },
            async reprocessSingleTimesheet() {
                var formdata = { 
                    week: this.week,
                    year: this.year

                }; 

                this.working = true

                BookingService.reprocessTimesheet(this.accessToken, formdata, this.modalBooking.id)
                .then(
                    (invoices => {
                        this.$set(this, "invoices", invoices);
                        this.working=false
                        this.processed=true
                        this.getApprovedTimesheets() 
                    }).bind(this)
                    
                )
                .catch((error) => { 
                    if (error.response.status == 400) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                })
            },
           
            viewProcessModal(booking) {
                this.modalBooking = booking
                this.showModal = true
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getProcessedBookings();
                //this.getThisWeekYear();
            });
        
        },
        computed: {
           
            
        },
        watch: {
            week: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getProcessedBookings();
                    
                }
                localStorage.payroll_report_week = this.week
            },
            year: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getProcessedBookings();
                    
                }
                localStorage.payroll_report_year = this.year
            }
        },
        mounted() {
            if (localStorage.payroll_report_week) {
                this.week = localStorage.payroll_report_week;
           }
            else{
                this.week = 1
            }

            if (localStorage.payroll_report_year) {
                this.year = localStorage.payroll_report_year;
           }
            else{
                this.year = 2023
            }
        },
    }
</script>