<template>
    <div>
        <div class="columns" >
            <div class="column">
                Week : <select class="select input" v-model="week">
                    <option :value="row" v-for="row in max_weeks" :key="row">{{ row }}</option>
                </select>
            </div>
            
            <div class="column">
                Year : <select class="select input" v-model="year">
                    <option :value="row" v-for="row in years" :key="row">{{ row }}</option>
                </select>
            </div>
        </div>

        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification " v-if="loading == false && members.length == 0">
            No members can be found matching your search criteria
        </div>

        
        <div v-if="loading == false && members.length > 0">
            
            <table class="table is-fullwidth  is-hoverable is-size-7 " >
                <thead>
                    <tr>
                        <th>Carer ID</th>
                        <th>Sage</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Timesheets</th>
                        <th>Hrs Worked</th>
                        <th>Avg Per Shift</th>
                        <th>Total Pay</th>
                        <th>ENI</th>
                        <th>Pension</th>
                        <th width="81">&nbsp;</th>
                        <th width="81">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="row in members">
                        <tr :key="'row-' + row.id" class="">
                            <td>{{ row.id }}</td>
                            <td>{{ row.sage_number }}</td>
                            <td>{{ row.surname }}, {{ row.forename}}</td>
                            <td>{{ row.email }}</td>
                            <td>{{ row.bookings.length }}</td>
                            <td>{{ row.hours_worked }}</td>
                            <td>{{ row.average_pay }}</td>
                            <td>{{ row.total_pay }}</td>
                            <td>{{ row.eni_total}}</td>
                            <td>{{ row.pension_total}}</td>
                            <td style="padding:0px">
                                <a :href="API_NAME + '/billing/member-payroll-summary?member_id=' + row.id + '&week=' + week + '&year=' + year" target="_blank"><button class="button is-small is-info " :disabled="working==true">Download</button></a>
                            </td>
                            <td style="padding:0px">
                                <button v-if="row.payroll_summary_sent == 1" :disabled="working==true" class="button is-small is-success is-fullwidth"  @click="sendPayrollSummary(row)">Send Again</button>
                                <button v-if="row.payroll_summary_sent == 0" :disabled="working==true" class="button is-small is-warning is-fullwidth"  @click="sendPayrollSummary(row)">Send By Email</button>
                            </td>
                        </tr>
                    </template>
                    
                
                </tbody>

            </table>
            
        </div>
        
        
        
    </div>
</template>

<script>
import BookingService from '@/services/BookingService.js';
//import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'PayrollReport',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   members: [],
                   loading: true,
                   showModal: false,
                   showNotesModal: false,
                   alertText: '',
                   modalBooking : [],
                   working:false,
                   booking_notes: '',
                   year: 2023,
                   week: 1,
                   years : [2021, 2022, 2023, 2024, 2025, 2026],
                   accessToken: '',
                   showInvoicesFor: 0,
                   API_NAME: process.env.VUE_APP_API_NAME,
                   
            }
        },
        methods: {
            
            async getProcessedBookings() {
                if(this.week > 0 && this.year > 0)
                {
                    this.loading = true;
                    
                    BookingService.getProcessedBookings(this.accessToken, this.week, this.year)
                    .then(
                        (members => {
                            this.$set(this, "members", members);
                            this.loading = false
                            this.working = false
                        
                        }).bind(this)
                    );
                }
                
                
            },
            
            viewTimesheetModal(booking) {
                
                this.modalBooking = booking
                this.showModal = true
            },
            showNotes(timesheet_notes) {
                this.booking_notes = timesheet_notes

                this.showNotesModal = true
            },
            async sendPayrollSummary(member) {
                var formdata = { 
                    week: this.week,
                    year: this.year

                }; 

                this.working = true

                BookingService.sendPayrollSummary(this.accessToken, formdata, member.id)
                .then( () => {
                    this.working=false;
                    member.payroll_summary_sent = 1
                    }
                )
                .catch((error) => { 
                    if (error.response.status == 400) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                })
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getProcessedBookings()
            });
        
        },
        computed: {
           
        },
        watch: {
            week: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getProcessedBookings();
                }
                localStorage.payroll_report_week = this.week
            },
            year: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getProcessedBookings();
                }
                localStorage.payroll_report_year = this.year
            }
        },
        mounted() {
            if (localStorage.payroll_report_week) {
                this.week = localStorage.payroll_report_week;
            }
            
            if (localStorage.payroll_report_year) {
                this.year = localStorage.payroll_report_year;
           }
            
        },
    }
</script>